$grid: (
    type: grid,
    default: default,
    generateGridClassesFromBreakpoints: true,
    columns: 12,
    column-gap: 1rem,
    row-gap: 1.5rem
);
$breakpoints: (
    desktop: 64rem,
    dividedteaser: 48em,
    history: 52em,
    history-lg: 74.3em,
    screenSmall: 767px
);
            @import "STYLE_UTILS/_base";.sw-pressrelease-card {
  background: map-get($backgrounds, light-bg);
}

.sw-pressrelease-card--borders {
  border: 1px solid map-get($borders, pressreleasecard);
}

.sw-pressrelease-card__category {
  @include label;

  color: map-get($colors, link);
  display: block;
  margin: map-get($grid, row-gap) map-get($grid, column-gap);
}

.sw-pressrelease-card__date {
  @include date;

  display: block;
  margin: map-get($grid, row-gap) map-get($grid, column-gap) 0;
}

.sw-pressrelease-card__img {
  .cmp-image__image {
    max-height: rem(488);
    object-fit: cover;
    object-position: top center;
  }
}

.sw-pressrelease-card__text {
  @include blog-teaser;

  color: currentColor;
  display: inline-block;
  margin: (map-get($grid, row-gap) / 2) map-get($grid, column-gap)
    map-get($grid, row-gap);
  text-decoration: none;

  > * {
    display: inline;
  }

  .sw-icon {
    height: 0.75em;
    stroke-width: 0.5;
    width: 0.75em;
  }
}
